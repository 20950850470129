<template>
  <div
    :class="{
      'container-fluid px-lg-5 mb-3': true,
      'margin-top-0421': this.$route.query.ismobileapp == undefined,
    }"
  >
    <div class="row">
      <div class="col-sm-12"><h1>About us</h1></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="text-justify">
          <p>
            Founded in August of 2015 and based in Belfast, Northern Ireland, UK, Trepr (Trip
            Representative) is one of the peer to peer economy sharing trusted community marketplace
            for people to list, discover, and substitute another person for their travel needs
            around the world.<br /><br />
            Almost every person has his/her local or international travel plans either occasionally
            or frequently. Trepr connects flight travelers and service seekers all over the world to
            exchange their travel plan and invoke travel services such as people (Flight
            Companionship), package (Parcel Delivery), and product (Shopping Concierge), safely,
            cheaply and promptly respectively anywhere in the world by substituting a traveler, who
            travels on the same route, and pay for each service through trepr.
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card text-center mt-1">
          <i class="fa-users fas circle-yes circle-yes-orange icon-third"></i>
          <div class="card-body">
            <h5 class="card-title text-primary">People</h5>
            <p class="card-text text-justify">
              People (companionship) Service facilitates a person to find an in flight companion for
              him/herself, Family members, Friends, and/or Relatives of all age groups from one
              place to another safely anywhere in the world.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <!--          <img src="..." class="card-img-top" alt="..." />-->
          <i class="fa-cubes fas circle-yes circle-yes-orange icon-third"></i>
          <div class="card-body">
            <h5 class="card-title text-primary">Package</h5>
            <p class="card-text text-justify">
              Package (courier) Service facilitates a person to find a courier to transport package
              such as Documents, Electronics, Food, and/or goods of different weight, sizes,
              dimensions and worth from one place to another cheaply anywhere in the world.
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card text-center">
          <!--          <img src="..." class="card-img-top" alt="..." />-->
          <i class="fa-box-open fas circle-yes circle-yes-orange icon-third"></i>
          <div class="card-body">
            <h5 class="card-title text-primary">Product</h5>
            <p class="card-text text-justify">
              Product (Concierge) Service facilitates a person to find a concierge to perform task
              such as to buy products from another country and deliver it at customers place
              promptly anywhere in the world.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <p>
          Trepr also provides world-class customer service, and a growing community of users, Trepr
          is the easiest way for people to monetize during their personal travel by show casing
          their travel plans to audience of billions.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h3>Leadership Team</h3>
        <div class="text-justify">
          Ranjith Gopalakrishnan is the CEO and founder of TREPR. He drives the company's vision,
          strategy and growth as they provide innovative and unique ways for people to fulfill their
          travel needs and earn in their travel without special efforts. Under Ranjith's leadership,
          Trepr stands at the forefront of the sharing economy, and is expanding in many countries
          on a rapid scale. With a small highly skilled technical team, his vision is being
          transformed accurately in the product to meet most of peoples' travel service demand
          across the world. He received a degree in computer science from Anna University, TN, India
          and held consulting positions in service based companies.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import WorldMap from './WorldMap.vue';
export default {
  name: 'AboutUs',
  // components: { WorldMap },
  created() {
    document.title = 'Trepr - About Us | Flight Companion & Parcel Delivery Services';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Trepr (Trip Representative) is one of the peer to peer economy sharing trusted community marketplace for people to list, discover, and substitute another person for their travel needs around the world.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
  },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/new/page/findcompanion';
</style>
